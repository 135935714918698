<template>
  <div class="user">
    <div class="_title">用户中心</div>
    <div v-if="$store.state.navigation" class="mobile-user">
      <v-card height="350" class="mt-4">
        <ul>
          <li @click="jump('/basic')">
            <div class="left">
              <img src="../../assets/img/icon/1.png" alt="" />
              <span>基本资料</span>
            </div>
            <div class="right">></div>
          </li>
          <!-- <li @click="jump('/cost')">
            <div class="left">
              <img src="../../assets/img/icon/2.png" alt="" />
              <span>费用支付</span>
            </div>
            <div class="right">></div>
          </li> -->
          <li @click="jump('/abstract')">
            <div class="left">
              <img src="../../assets/img/icon/3.png" alt="" />
              <span>报告提交</span>
            </div>
            <div class="right">></div>
          </li>
          <!-- <li @click="jump('/payRecord')">
            <div class="left">
              <img src="../../assets/img/icon/13.png" alt="" />
              <span>我的订单</span>
            </div>
            <div class="right">></div>
          </li> -->
           <!-- <li @click="jump('/hotelOrder')">
            <div class="left">
              <img src="../../assets/img/icon/18.png" alt="" />
              <span>酒店订单</span>
            </div>
            <div class="right">></div>
          </li> -->
         
          <li @click="jump('/changePassword')">
            <div class="left">
              <img src="../../assets/img/icon/5.png" alt="" />
              <span>修改密码</span>
            </div>
            <div class="right">></div>
          </li>
          <li @click="FedLogOut">
            <div class="left">
              <img src="../../assets/img/icon/6.png" alt="" />
              <span>退出登录</span>
            </div>
            <div class="right">></div>
          </li>
        </ul>
      </v-card>
    </div>
    <div v-else class="pc-user pl-12 pr-12">
      <v-row class="mt-10">
        <v-tabs v-model="tabsNum" vertical @change="tabChange">
          <v-tab> 基本资料 </v-tab>
          <!-- <v-tab> 费用支付 </v-tab> -->
          <v-tab> 报告提交 </v-tab>
          <!-- <v-tab @click="handleRecord"> 我的订单 </v-tab>
          <v-tab> 酒店订单 </v-tab> -->
     
          <v-tab> 修改密码 </v-tab>
          <v-tab @click="FedLogOut"> 退出登录 </v-tab>
          <!-- 基本资料 -->
          <v-tab-item>
            <basic :title="false" className="item-boeder ml-10"></basic>
          </v-tab-item>

          <!-- 费用支付 -->
          <!-- <v-tab-item>
            <cost
              v-if="!pay"
              ref="cost"
              :title="false"
              className="item-boeder ml-10 pa-4"
            ></cost>
            <paySuccess v-else :title='false'></paySuccess>
          </v-tab-item> -->

          <!-- 摘要墙报 -->
          <v-tab-item>
            <abstract
              :title="false"
              ref="abstract"
              className="item-boeder ml-10 pa-4"
            ></abstract>
          </v-tab-item>
          <!-- 我的订单 -->
          <!-- <v-tab-item>
            <record
              :title="false"
              ref="record"
              className="item-boeder ml-10 pa-4"
            ></record>
          </v-tab-item> -->
          <!-- 酒店订单 -->
           <!-- <v-tab-item>
            <hotelOrder
              :title="false"
              ref="hotelOrder"
              className="item-boeder ml-10 pa-4"
            ></hotelOrder>
          </v-tab-item>
       -->

          <!-- 修改密码 -->
          <v-tab-item>
            <changePassword
              ref="changePassword"
              :title="false"
              className="item-boeder ml-10 pa-4"
            ></changePassword>
          </v-tab-item>
        </v-tabs>
      </v-row>
    </div>
  </div>
</template>

<script>
import basic from '../basic';
import abstract from '../abstract'
// import cost from '../cost';


import changePassword from '../changePassword';
// import record from '../pay/record.vue';
// import paySuccess from '../pay/success.vue';
// import hotelOrder from '../hotelOrder'
export default {
  data() {
    return {
      tabsNum: 0,
      pay:false
    };
  },
  components: {basic, abstract, changePassword },
  created() {
    this.tabsNum = Number(this.$route.query.num || 0);
    this.getList()
  },
  watch:{
    $route:{
      handler:function(val){
        if(val.query.num){
           this.tabsNum=Number(val.query.num||0)
        }
        
       
      }
    }
  },
  mounted() {},
  methods: {
    async jump(url) {
      if(url=='/cost'){
         await this.getList()
        if(this.pay){
          this.$router.push('/paySuccess');
        }else{
           this.$router.push(url);
        }
      }else{
         this.$router.push(url);
      }
      
     
    },
    async getList() {
      await this.$request({
        url: "/order/list",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.code == 200) {
          let payList = []
          res.data.map((item) => {
            if(item.payStatus==1){
               payList.push(item)
              
            }
          });
          if(payList.length>0){
            this.pay=true
          }else{
            this.pay=false
          }
        
        }
      });
     
    },
    tabChange() {
      this.$router.push({query:{num:this.tabsNum}})
      this.$refs.cost && this.$refs.cost.clear();
      this.$refs.abstract && this.$refs.abstract.clear();
      this.$refs.record && this.$refs.record.success();
      this.$refs.changePassword && this.$refs.changePassword.clear();
      
    },
    handleRecord(){
     
      this.$refs.record && this.$refs.record.success();
    },
    // 退出登录
    FedLogOut() {
      this.$store
        .dispatch('FedLogOut')
        .then(() => {
          this.$message.tips('退出成功');
          this.$router.push({ path: this.redirect || '/' }).catch(() => {});
        })
        .catch(() => {
          this.getCode();
        });
    },
  },
};
</script>

<style lang="less" scoped>
.user {
  padding-bottom: 150px;
  .required {
    position: relative;
    &::before {
      content: '*';
      // position: absolute;
      left: 10%;
      top: 30%;
      font-size: 20px;
      color: red;
    }
  }
  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  .pc-user {
    .v-btn--block {
      min-width: 0px !important;
      width: 300px;
      margin: 0 auto;
    }
    .v-tab,
    .v-card__text {
      font-size: 15px !important;
      line-height: 25px;
    }

    .item-boeder {
      border: 1px solid #c4d5e0;
    }

    .wd-2 {
      width: 200px !important;
    }
    .form-title {
      font-size: 18px;
      background: #efefef;
      color: #3b86aa;
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      border-radius: 10px;
      font-weight: bold;
    }
  }
  .mobile-user {
    ul {
      padding-left: 0;
      li {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        border-bottom: #dadada solid 1px;
        padding: 0 20px;
        .left {
          img {
            vertical-align: top;
            width: 20px;
            height: 20px;
          }
          span {
            margin-left: 20px;
          }
        }
      }
    }
  }

  ._blue {
    color: #337fe5;
  }
  .desc-title {
    color: #666666;
  }
  .bold {
    font-weight: bold;
  }
  ._red {
    color: #e53333;
  }
}
</style>
